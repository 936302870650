import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { isAndroid } from 'react-device-detect';
import LandingPageTitle from '~src/components/LandingPageTitle';
import RoundedLinkButton from '~src/components/buttons/RoundedLinkButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '106px',
    paddingBottom: '190px',
    gap: 34,
    [theme.breakpoints.down('xs')]: {
      paddingTop: '60px',
      paddingBottom: '40px'
    }
  },
  section: {
    position: 'relative',
    backgroundColor: props => props.background_color
  },
  featureArea: {
    display: 'grid',
    width: '100%',
    marginTop: '44px',
    marginBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px'
    }
  },
  featureImage: {
    overflowX: 'hidden',
    justifySelf: 'center',
    alignSelf: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxHeight: '60vh'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '95vw'
    }
  }
}));

const FeatureMobileApp = ({
  feature_app_title,
  text_color,
  ios_cta_text,
  ios_app_link,
  android_cta_text,
  android_app_link,
  background_color,
  feature_image,
  feature_image_mobile
}) => {
  const classes = useStyles({ background_color });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const { ctaText, ctaLink, ctaTarget } = useMemo(() => {
    if (isAndroid) {
      return {
        ctaText: android_cta_text,
        ctaLink: android_app_link?.url,
        ctaTarget: android_app_link?.target || '_self'
      };
    }
    return {
      ctaText: ios_cta_text,
      ctaLink: ios_app_link?.url,
      ctaTarget: ios_app_link?.target || '_self'
    };
  }, [android_cta_text, android_app_link, ios_cta_text, ios_app_link]);

  const image = useMemo(() => {
    if (isMobile && feature_image_mobile?.url) {
      return feature_image_mobile;
    }
    return feature_image;
  }, [isMobile, feature_image, feature_image_mobile]);

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <LandingPageTitle
          title={feature_app_title}
          fontSize={72}
          fontSizeXS={32}
          color={text_color}
          maxWidth="1184px"
          maxWidthXS="341px"
        />
        <RoundedLinkButton
          text={ctaText}
          to={ctaLink}
          target={ctaTarget}
          size="small"
          variant="blue"
        />
        <div className={classes.featureArea}>
          <img
            key={image?.url}
            src={image?.url}
            alt={image?.alt}
            className={classes.featureImage}
          />
        </div>
      </div>
    </div>
  );
};

FeatureMobileApp.propTypes = {
  feature_app_title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  background_color: PropTypes.string,
  text_color: PropTypes.string,
  ios_cta_text: PropTypes.string.isRequired,
  ios_app_link: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string
  }).isRequired,
  android_cta_text: PropTypes.string.isRequired,
  android_app_link: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string
  }).isRequired,
  feature_image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired,
  feature_image_mobile: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

FeatureMobileApp.defaultProps = {
  background_color: '#F8A39B',
  text_color: '#FFFFFF',
  feature_image_mobile: null
};

export default FeatureMobileApp;
